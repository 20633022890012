exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-availabilities-commercial-js": () => import("./../../../src/pages/availabilities/commercial.js" /* webpackChunkName: "component---src-pages-availabilities-commercial-js" */),
  "component---src-pages-availabilities-residential-js": () => import("./../../../src/pages/availabilities/residential.js" /* webpackChunkName: "component---src-pages-availabilities-residential-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contentful-property-url-js": () => import("./../../../src/pages/{contentfulProperty.url}.js" /* webpackChunkName: "component---src-pages-contentful-property-url-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-properties-commercial-js": () => import("./../../../src/pages/properties/commercial.js" /* webpackChunkName: "component---src-pages-properties-commercial-js" */),
  "component---src-pages-properties-residential-js": () => import("./../../../src/pages/properties/residential.js" /* webpackChunkName: "component---src-pages-properties-residential-js" */)
}

